var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "all-text" }, [
    _c("div", { staticClass: "left" }, [
      _c("div", [
        _c("div"),
        _c("div", [
          _c(
            "div",
            { staticStyle: { border: "1px solid #ccc" } },
            [
              _c("Toolbar", {
                staticStyle: { "border-bottom": "1px solid #ccc" },
                attrs: {
                  editor: _vm.editor,
                  defaultConfig: _vm.toolbarConfig,
                  mode: _vm.mode,
                },
              }),
              _c("Editor", {
                staticStyle: { height: "500px", "overflow-y": "hidden" },
                attrs: { defaultConfig: _vm.editorConfig, mode: _vm.mode },
                on: { onCreated: _vm.onCreated },
                model: {
                  value: _vm.html,
                  callback: function ($$v) {
                    _vm.html = $$v
                  },
                  expression: "html",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "right" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="all-text">
    <div class="left">
      <div>
        <div></div>
        <div>
          <div style="border: 1px solid #ccc;">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden;"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: {
        excludeKeys:[
          'blockquote',
          'headerSelect',
          'bgColor',
          'fontFamily',
          'lineHeight',
          'bulletedList',
          'numberedList',
          'todo',
          'group-justify',
          'group-indent',
          'emotion',
          'insertLink',
          'group-image',
          'group-video',
          'insertTable',
          'codeBlock',
          'divider'
        ]
      },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default' // or 'simple'
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    }
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    }, 1500)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style scoped lang="less">
.all-text {
  padding: 36px 60px 36px 60px;
  display: flex;
  .left {
    width: 70%;
    height: calc(100vh - 205px);
    border-radius: 10px;
    padding-right: 30px;
    > div {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.8rem;
      width: 100%;
      height: 100%;
    }
  }
  .right {
    width: 30%;
    height: calc(100vh - 205px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.8rem;
    border-radius: 10px;
  }
}
</style>
